<template>
  <div
    class="white-img-item"
    :style="imgPosition === 'right' && 'flex-direction: row-reverse;'">
    <div class="white-img-item__img">
      <img :src="img" alt="">
    </div>
    <div
      class="white-img-item__info"
      :style="imgPosition === 'left' && 'padding-left: 40px; padding-right: 0px;'"
      v-html="info"></div>
  </div>
</template>
<script>
export default {
  props: {
    img: {
      type: String,
      required: true
    },
    imgPosition: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.white-img-item {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 3px 6px 9px 1px rgba(0,0,0,0.1);
  border-radius: 10px;
  padding: 50px;
  overflow: hidden;

  @media screen and (max-width: 750px) {
    flex-direction: column !important;
    align-items: center;
    box-shadow: none;
  }

  &:nth-child(n + 2) {
    margin-top: 0px;
    margin-bottom: 60px;
  }
}
.white-img-item__img {
  width: 340px;
  margin-right: 0px;
  float: left;

  @media screen and (max-width: 750px) {
    float: none;
  }

  img {
    width: 100%;

    @media screen and (max-width: 750px) {
      width: 900px;
      margin: 40px 0 20px -280px !important;      
      padding: 0 !important;
    }
  }
}
.white-img-item__info {
  flex: 1;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  line-height: 1.8;
  text-align: justify;

  @media screen and (max-width: 750px) {
    padding: 0 !important;
    font-size: 45px;
    margin-left: 0 !important;
    margin-right: 0 !important;;
  }
}
</style>
